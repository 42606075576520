<template>
  <div class="main">
    <AccountIsActivatedCanNotDelete
      ref="accountIsActivatedCanNotDelete"/>
    <Waiting
      ref="waiting"/>
    <DeleteAccountSuccess
      ref="deleteAccountSuccess"/>
  </div>
</template>

<script>
import axios from 'axios'
import Waiting from '@/components/Register/Waiting'
import AccountIsActivatedCanNotDelete from '@/components/Register/AccountIsActivatedCanNotDelete'
import DeleteAccountSuccess from '@/components/Register/DeleteAccountSuccess'

export default {
  name: 'RemoveAccount',
  components: { Waiting, AccountIsActivatedCanNotDelete, DeleteAccountSuccess },
  data () {
    return {
      payloadDeleteAccount: {
        dbid: 0,
        token: ''
      }
    }
  },
  created () {
    this.payloadDeleteAccount.dbid = this.$route.params.dbid
    this.payloadDeleteAccount.token = this.$route.params.token
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.$route.params.uid
    }
    axios
      .post(this.$store.state.path_api + '/register/delete_account_from_first_email', this.payloadDeleteAccount, { headers })
      .then(response => {
        this.$refs.waiting.hideWaiting()
        this.$refs.deleteAccountSuccess.showSuccess()
      })
      .catch(error => {
        if (error.response.data === 'User already have confirmed email') {
          this.$refs.waiting.hideWaiting()
          this.$refs.accountIsActivatedCanNotDelete.showError()
        } else {
          this.$refs.waiting.hideWaiting()
          this.$store.state.error = error
          this.$store.state.proces_Name = 'Account delete'
          this.$store.state.error_response = error.response
          this.$store.state.error_message = error.response.data
          this.$router.push({ name: 'ErrorPage', params: { dbid: this.dbid } })
        }
      })
  },
  mounted () {
    this.$refs.waiting.showWaiting()
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";

</style>
