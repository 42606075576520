<template>
  <div class="centred">
    <div class="master_container">
      <div class="rowContainer">
        <div class="value">Lp.</div>
        <div class="name">Nazwa</div>
        <div class="short">Skrót</div>
      </div>
      <hr>
      <div v-for="(element, index) in data_months" :key="index" >
        <div class="rowContainer">
          <div class="value">{{element.value}}</div>
          <div class="name">{{element.name}}</div>
          <div class="short">{{element.short}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import months from '@/views/Data/eng_months.json'
export default {
  name: 'Month.vue',
  data () {
    return {
      data_months: months.months_list
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
.rowContainer {
  display: flex;
}
.master_container{
  width: min-content;
}
.centred{
  display: flex;
  align-items: center;
  justify-content: center;
}
.value{
  width: 50px;
  font-size: 20px;
  color: #e0fbfc;
  text-align: center;
}
.name{
  width: 120px;
  font-size: 20px;
  color: #e0fbfc;
  text-align: center;
}
.short{
  width: 50px;
  font-size: 20px;
  color: #e0fbfc;
  text-align: center;
}
</style>
