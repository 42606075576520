<template>
  <div class="main">
  <div>
  <div class="small_main_container" id="successActivate">
    <div>
      <div>
        Poprawnie aktywowano konto
      </div>
      <i class="far fa-check-circle" style="font-size: 40px"></i>
      <div class="smallText">
        <div>
          Proces aktywacji konta dobiegł końca. Możesz się zalogować do swojego konta.
          Dziękujemy, że jesteś z nami.
          Zachęcamy do polubienia nas na Facebooku oraz Instagramie.
        </div>
        <div class="social">
          <div class="social facebook" @click="globalGoTo('https://www.facebook.com/TaPo24/','Link do strony ', 'web', 'SOCIAL')">
            <div class="icon social facebook"><span><i class="fab fa-facebook-f"></i></span></div>
          </div>
          <div class="social instagram" @click="globalGoTo('https://www.instagram.com/tapo24.pl/','Link do strony IG', 'web', 'SOCIAL')">
            <div class="icon social instagram"><span><i class="fab fa-instagram"></i></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="footer" @click="click_to_login" id="footerActivate" v-if="uidExist">
      Zaloguj się <i class="fa-regular fa-hand-pointer"></i>
    </div>
  </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ActivateAccount',
  data () {
    return {
      payloadActivateAccount: {
        dbid: 0,
        token: ''
      },
      uidExist: false
    }
  },
  methods: {
    click_to_login () {
      this.$router.push({ name: 'Login' })
    }
  },
  created () {
    const UID = localStorage.getItem('UID')
    if (UID) {
      this.uidExist = true
    }
  },
  mounted () {
    this.payloadActivateAccount.dbid = this.$route.params.dbid
    this.payloadActivateAccount.token = this.$route.params.token
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.$route.params.uid
    }
    axios
      .post(this.$store.state.path_api + '/register/activate_account', this.payloadActivateAccount, { headers })
      .then(response => {
        document.getElementById('successActivate').style.display = 'flex'
        document.getElementById('successActivate').style.visibility = 'unset'
        document.getElementById('footerActivate').style.display = 'flex'
        document.getElementById('footerActivate').style.visibility = 'unset'
      })
      .catch(error => {
        if (error.response.data === 'The 2FA key is incorrectly or expired') {
          console.log('error.response.data')
          this.$router.push({ name: 'ResendToken', params: { dbid: this.$route.params.dbid, uid: this.$route.params.uid } })
        } else {
          this.$store.state.error = error
          this.$store.state.proces_Name = 'Account activation'
          this.$store.state.error_response = error.response
          this.$store.state.error_message = error.response.data
          this.$router.push({ name: 'ErrorPage', params: { dbid: this.dbid } })
        }
      })
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";

#successActivate {
  font-family: $text-family;
  display: none;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}
#footerActivate {
  display: none;
  justify-content: center;
  visibility: hidden;
}
.smallText {
  font-size: 12px;
  overflow-wrap: normal;
}
.social{
  display: inline-flex;
  list-style: none;
}
.social .icon{
  background: #293241;
  color: #ee6c4d;
  border-radius: 50%;
  padding: 5px;
  width: 15px;
  height: 15px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.footer{
  margin: 10px 10px;
  width: auto;
  background-color: #3D5A80;
  border-radius: 8px;
  padding: 20px 10px;
  height: min-content;
  font-family: $text-family;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
}
.footer:hover {
  cursor: pointer;
  background-color: #9cbad3;
}
@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}
</style>
