<template>
  <div>
    <b-overlay :show=notificationProcessing rounded="sm"
               variant="dark"
               opacity="0.5">
      <div v-for="(element, index) in notificationListData" :key="index">
      <NotificationComponent
        :id="element.id"
      :notification="element.notification"/>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Proszę czekać .... generowanie trasy patrolowej</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NotificationComponent from '@/components/NotificationComponent.vue'

export default {
  name: 'NotificationPage.vue',
  computed: {
    ...mapState([
      'notificationListData',
      'notificationCount',
      'notificationIncoming',
      'notificationBlock',
      'notificationProcessing'
    ])
  },
  components: {
    NotificationComponent
  },
  updated () {
    if (this.notificationListData.length <= 0) {
      this.$store.state.notificationBlock = false
    }
  },
  destroyed () {
    this.$store.state.notificationBlock = false
  }
}
</script>

<style scoped>

</style>
