<template>
  <div class="main">
    <Error/>
  </div>
</template>

<script>
import Error from '@/components/Error'
export default {
  name: 'ErrorPage',
  components: {
    Error
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

</style>
