<template>
  <div class="small_main_container" id="error">
    <div>
      <div>
        Błąd
      </div>
      <div>{{ error_message }}</div>
      <div class="smallText">Jeśli błąd sie powtarza proszę skontaktować się z nami wysyłając zrzut ekranu wraz z błędem</div>
      <i class="fa-solid fa-circle-exclamation" style="font-size: 30px; color: #bd0000" ></i>
      <div class="smallText">
        <div>Serwer napotkał na błąd:</div>
        <div>Nazwa procesu: {{proces_Name}}</div>
        <div>{{ error }}</div>
        <div>{{ error_message }}</div>
        <div>{{ error_response }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Error',
  computed: {
    ...mapState([
      'error',
      'error_message',
      'proces_Name',
      'error_response'
    ])
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";
#error {
  font-family: $text-family;
// display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  display: flex;
}
.smallText {
  font-size: 12px;
}
</style>
