<template>
  <div id="main">
    <div v-if="true" class="containerMy">
      <div class="element" @click="globalGoTo('/Month/', null,null, null)">
        <div class="icon"><i class="fas fa-envelope"></i></div>
        <div class="label">Miesiące</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainEnglish.vue'
}
</script>

<style scoped lang="scss">
@import "src/views/grid";
</style>
