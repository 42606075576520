<template>
    <div class="small_main_container" id="formForgot">
      <div class="group_element">
        <div class="text">
          Przywracanie hasła krok 2
        </div>
        <div class="text">
          Wprowadź hasło
        </div>
        <div class="grid">
          <div :class="[!validator.password && data.password === '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-question"></i>
          </div>
          <div :class="[validator.password && data.password === '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-question"></i>
          </div>
          <div :class="[validator.password && data.password !== '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
          </div>
          <div :class="[!validator.password && data.password !== '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
          </div>
          <input placeholder="Wprowadź swoje nowe hasło" type="password" @change="valid_password" id="forgot_password" v-model="data.password" @keydown.enter="keypress_password">
          <div class="hint_Text">{{ hint.password }}</div>
        </div>
      </div>
      <div :class="[validator.password ? 'group_element' : 'none']">
        <div class="text">
          Powtórz hasło
        </div>
        <div class="grid">
          <div :class="[!validator.re_password && data.re_password === '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-question"></i>
          </div>
          <div :class="[validator.re_password && data.re_password === '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-question"></i>
          </div>
          <div :class="[validator.re_password && data.re_password !== '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
          </div>
          <div :class="[!validator.re_password && data.re_password !== '' ? 'icon' : 'hide']">
            <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
          </div>
          <input placeholder="Powtórz hasło" type="password" @change="valid_rePassword" id="forgot_re_password" v-model="data.re_password">
          <div class="hint_Text">{{ hint.re_password }}</div>
        </div>
      </div>
      <div class="orange_button" v-if="validator.re_password"  @click="emitNewPassword">Nadaj nowe hasło <i class="fa-solid fa-right-to-bracket"></i></div>
      <div class="orange_button_disabled" v-if="!validator.re_password">Nadaj nowe hasło  <i class="fa-solid fa-right-to-bracket"></i></div>
    </div>
</template>

<script>
export default {
  name: 'SetNewForgotPasswordForm.vue',
  data () {
    return {
      validator: {
        password: false,
        re_password: false
      },
      data: {
        password: '',
        re_password: ''
      },
      hint: {
        password: '',
        re_password: ''
      },
      payloadForgotPassword: {
        token: '',
        password: ''
      }

    }
  },
  watch: {
    'data.password' () {
      this.valid_password()
    },
    'data.re_password' () {
      this.valid_rePassword()
    }
  },
  methods: {
    emitNewPassword () {
      this.$emit('on-send-clicked', this.data.password)
    },
    keypress_password () {
      document.getElementById('forgot_re_password').select()
    },
    valid_password () {
      if (this.data.password.length >= 8 && /\d/.test(this.data.password) && /[a-z]/.test(this.data.password) && /[A-Z]/.test(this.data.password) && /[!@#$%^&*)(+=._-]/.test(this.data.password)) {
        document.getElementById('forgot_password').className = ''
        this.hint.password = ''
        this.validator.password = true
      } else {
        document.getElementById('forgot_password').className = ''
        document.getElementById('forgot_password').className = 'test'
        this.hint.password = 'Hasło musi mieć minimalną długość 8 znaków oraz zawierać 1 dużą i małą literę, liczbę, znak specjalny'
      }
    },
    valid_rePassword () {
      if (this.validator.password) {
        if (this.data.password === this.data.re_password) {
          document.getElementById('forgot_re_password').className = ''
          this.validator.re_password = true
          this.hint.re_password = ''
        } else {
          this.validator.re_password = false
          document.getElementById('forgot_re_password').className = ''
          document.getElementById('forgot_re_password').className = 'test'
          this.hint.re_password = 'Wprowadź ponownie te same hasło'
        }
      } else {
        this.validator.re_password = false
        document.getElementById('forgot_re_password').className = ''
        document.getElementById('forgot_re_password').className = 'test'
        this.hint.re_password = 'Wprowadź najpierw poprawne hasło!'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";
.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.password_and_forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.orange_button {
  margin-top: 10px;
  width: auto;
  justify-content: center;
}
.orange_button_disabled {
  background-color: #293241;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  transition: 0.5s;
  margin-top: 10px;
  width: auto;
  justify-content: center;
}
.test {
  background: #ff0000;
  border: 5px solid #bd0000;
  border-radius: 5px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
input{
  width: auto;
  border-width: 0;
  border-radius: 5px;
  padding: 4px 10px;
  margin-left: 10px;
}
.group_element{
  font-family: $text-family;
  font-weight: bold;
  transition: 1s;
}
.input_box{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}
.text {
  margin-bottom: 10px;
  text-align: center;
}
.small_main_container{
  margin: 20px 10px 10px 10px;
  transition: height 1s;
  transition-timing-function: ease;
}
.checbox{
  font-size: 35px;
}
.hint_Text {
  grid-row-start: 2;
  grid-column-start: 2;
  margin-left: 10px;
  font-weight: lighter;
  font-size: 12px;
}
.hint_Text_register{
  font-weight: lighter;
  font-size: 12px;
  text-align: center;
}
.hide {
  visibility: hidden;
  display: none;
}
.grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto;
  grid-gap: 6px;
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  align-items: center;
}
.none {
  display: none;
}
.accept_box{
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
