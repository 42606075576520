<template>
  <div class="small_main_container">
    <div class="master_title"> {{ notification.topic }}</div>
    <div class="content"> {{ notification.content }}</div>
    <div class="orange_button" @click="clickRead"> Odczytano  <i class="fa-solid fa-clipboard-check fa-beat"></i></div>
  </div>
</template>

<script>

export default {
  name: 'NotificationComponent.vue',
  methods: {
    clickRead () {
      this.$store.dispatch('mark_as_read_notification', this.id).then(data => {
        this.$store.state.notificationProcessing = false
      })
    }
  },
  props: {
    id: null,
    notification: {
      id: null,
      creatorDbId: null,
      editorDbId: null,
      editTime: null,
      topic: null,
      content: null,
      type: null,
      timeFrom: null,
      timeTo: null,
      creationTime: null,
      active: null,
      createdList: null
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";
.small_main_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.content{
  color: $text;
}

</style>
