<template>
  <div class="small_main_container">
    <b-modal id="modal-center"
             ref="my-modal"
             header-bg-variant="warning"
             footer-bg-variant="warning"
             @ok="deleteNotification"
             centered
             title="Potwierdź akcję">
      <p class="my-4">Czy aby napewno usunąć wpis?
        Spowoduje to skasowanie statystyk z nim zwiazanych oraz usunie listę wysyłkową.
        Operacji tej nie będzie można cofnąć ani przerwać jej wykonywania</p>
    </b-modal>
    <div class="innerContainer">
    <b-overlay :show="loadingForm" rounded="sm"
               variant="light"
               opacity="0.5">
      <div class="formMessage">
        <b-form @submit="updateNotification">
          <b-form-group
            id="input-group-1"
            label="Temat wiadomości:"
            label-for="input-1"
            description="Wprowadź krótki tytuł wiadomości"
          >
            <b-form-input
              id="input-1"
              v-model="form.topic"
              placeholder="Wprowadź temat"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Treść wiadomości" label-for="input-2">
            <b-form-textarea
              id="input-2"
              v-model="form.content"
              placeholder="Wprowadź treść"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-3" label="Typ:" label-for="input-3" description="Tego pola nie można edytować">
            <b-form-select
              disabled
              id="input-3"
              v-model="form.type"
              :options="type"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-group-4" label="Data od:" label-for="timeFrom" description="Od kiedy ma być dostarczana">
            <b-form-datepicker
              id="timeFrom"
              v-model="form.timeFrom"
              required
              class="mb-2">
            </b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-group-5" label="Data do:" label-for="timeTo" description="Do kiedy ma być ważna wiadomość">
            <b-form-datepicker
              id="timeTo"
              v-model="form.timeTo"
              required
              class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-group-6" >
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.active"
              name="checkbox-1"
              :value=true
              :unchecked-value=false
            >
              Wiadomość aktywna
            </b-form-checkbox>
          </b-form-group>

          <b-button type="submit" variant="primary">Zapisz</b-button>
          <b-button variant="danger" v-if="!form.createdList" @click="generateList">Wygeneruj listę</b-button>
          <b-button variant="danger" @click="showConfirmForDelete">Usuń</b-button>
          <b-button variant="danger" @click="back">Powrót</b-button>
        </b-form>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Proszę czekać .... obliczam to loyu pocisku</p>
        </div>
      </template>
    </b-overlay>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EditNotification.vue',
  data () {
    return {
      loadingForm: true,
      form: {
        createdList: false,
        id: 0,
        topic: '',
        content: '',
        type: null,
        timeFrom: '',
        timeTo: '',
        active: true
      },
      type: [{ text: 'Wybierz opcję', value: null },
        { text: 'Wiadomość standardowa', value: 'Normal' },
        { text: 'Wiadomość reklamowa', value: 'Adv' },
        { text: 'Wiadomość techniczna', value: 'Tech' }]
    }
  },
  mounted () {
    this.$store.dispatch('get_admin_notification_by_id', this.$route.params.id)
      .then(data => {
        this.loadingForm = false
        this.form.createdList = data.createdList
        this.form.id = data.id
        this.form.topic = data.topic
        this.form.content = data.content
        this.form.type = data.type
        this.form.timeFrom = this.convertTimestampToRRRMMDD(data.timeFrom)
        this.form.timeTo = this.convertTimestampToRRRMMDD(data.timeTo)
        this.form.active = data.active
      }
      )
  },
  methods: {
    deleteNotification () {
      this.loadingForm = true
      const payload = {
        id: this.form.id
      }
      this.$store.dispatch('delete_admin_notification', payload)
        .then(data => {
          this.$router.push({ name: 'Administration' })
        })
        .then(error => {
          this.catchErrorPage(error, 'Edit Notification')
        })
    },
    showConfirmForDelete () {
      this.$refs['my-modal'].show()
    },
    updateNotification (event) {
      this.loadingForm = true
      event.preventDefault()
      const dateTo = new Date(this.form.timeTo)
      const dateFrom = new Date(this.form.timeFrom)
      this.form.timeTo = dateTo.getTime() / 1000
      this.form.timeFrom = dateFrom.getTime() / 1000
      this.$store.dispatch('put_modify_notification', this.form)
        .then(data => {
          this.$router.push({ name: 'Administration' })
        })
        .catch(error => {
          this.catchErrorPage(error, 'EditNotificationPage')
        })
    },
    back () {
      this.$router.push({ name: 'Administration' })
    },
    generateList () {
      this.loadingForm = true
      const payload = {
        id: this.form.id
      }
      this.$store.dispatch('post_generate_list', payload)
        .catch(error => {
          this.catchErrorPage(error, 'EditNotificationPage')
        })
        .then(data => {
          this.loadingForm = false
          this.form.createdList = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/views/main_layout';
@import 'src/views/others';
.innerContainer {
  background: #2B3442FF;
  padding: 5px;
}

</style>
