<template>
  <div class="small_main_container">
    <div class="mytabs">
      <input type="radio" id="tab_info" name="mytabs" checked="checked" >
      <label  for="tab_info">O koncie  <i class="fa-solid fa-user-tie"></i></label>
      <div class="tab">
        <AboutUser/>
      </div>
<!--      <input type="radio" id="tab_esw" name="mytabs" >-->
<!--      <label  for="tab_esw">Ustawienia  <i class="fa-sharp fa-solid fa-gear"></i></label>-->
<!--      <div class="tab">-->
<!--        TEST-->
<!--      </div>-->
<!--      <input type="radio" id="tab_log" name="mytabs" >-->
<!--      <label  for="tab_log">Logi  <i class="fas fa-comment" ></i></label>-->
<!--      <div class="tab">-->
<!--        Logi-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import AboutUser from '@/components/Profile/AboutUser.vue'

export default {
  name: 'Profile.vue',
  components: {
    AboutUser
  }
}
</script>

<style scoped lang="scss">
@import 'src/views/main_layout';
@import 'src/views/others';
.small_main_container {
  padding: 0 0 5px 0px;
  background: unset;
}
.mytabs {
  display: flex;
  flex-wrap: wrap;
}
.mytabs input[type="radio"] {
  display: none;
}
.mytabs label {
  font-family: 'Itim', cursive;
  background: #ee6c4d;
  margin-right: 10px;
  border-radius: 5px 5px 0 0;
  padding: 15px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.mytabs .tab {
  background: #3D5A80;
  width: 100%;
  padding: 20px;
  order: 1;
  display: none;
  color: #fff;
}
.mytabs input[type="radio"]:checked + label + .tab {
  display: block;
}
.mytabs input[type="radio"]:checked + label {
  background: #3D5A80;
}
.mytabs input[type="radio"]:not(:checked):hover + label {
  background: #3D5A80;
}
.mytabs label i {
  font-size: 20px;
}

</style>
