<template>
  <div class="main">
    <ResendTokenSuccess
    ref="resendToken"/>
    <AccountIsActivated
    ref="accountActivated"/>
    <Waiting
    ref="waiting"/>
    <div class="small_main_container" id="resendToken">
      <div>
        <div>
          Błąd
        </div>
        <div>
          Token aktywacyjny jest niepoprawny
        </div>
        <div>
          lub stracił ważność
        </div>
        <div class="smallText">Jeśli błąd sie powtarza proszę skontaktować się z nami wysyłając zrzut ekranu wraz z błędem</div>
        <i class="fa-solid fa-circle-exclamation" style="font-size: 30px; color: #bd0000" ></i>
        <div class="activateBTN"><div class="orange_button" @click="resendToken">Wyślij ponownie  <i class="fa-solid fa-key"></i></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ResendTokenSuccess from '@/components/Register/ResendTokenSuccess'
import AccountIsActivated from '@/components/Register/AccountIsActivated'
import Waiting from '@/components/Register/Waiting'

export default {
  name: 'ResendToken',
  components: { Waiting, AccountIsActivated, ResendTokenSuccess },
  data () {
    return {
      dbid: {
        dbid: 0
      }
    }
  },
  mounted () {
    this.$refs.waiting.showWaiting()
    document.getElementById('resendToken').style.visibility = 'hidden'
    document.getElementById('resendToken').style.display = 'none'
  },
  created () {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.$route.params.uid
    }
    this.dbid.dbid = this.$route.params.dbid
    axios
      .post(this.$store.state.path_api + '/register/check_email_confirm', this.dbid, { headers })
      .then(successResponse => {
        document.getElementById('resendToken').style.visibility = 'hidden'
        document.getElementById('resendToken').style.display = 'none'
        this.$refs.waiting.hideWaiting()
        this.$refs.accountActivated.showSuccess()
      })
      .catch(error => {
        console.log(error)
        this.$refs.waiting.hideWaiting()
        document.getElementById('resendToken').style.visibility = 'unset'
        document.getElementById('resendToken').style.display = 'flex'
      })
  },
  methods: {
    resendToken () {
      const headers = {
        'Content-Type': 'application/json',
        'INSTALLATION-UID': this.$route.params.uid
      }
      this.dbid.dbid = this.$route.params.dbid
      axios
        .post(this.$store.state.path_api + '/register/resend_activation_Token', this.dbid, { headers })
        .then(successResponse => {
          document.getElementById('resendToken').style.visibility = 'hidden'
          document.getElementById('resendToken').style.display = 'none'
          this.$refs.resendToken.showSuccessRegenerateToken()
        })
        .catch(error => {
          if (error.response.data === 'User already have confirmed email') {
            document.getElementById('resendToken').style.visibility = 'hidden'
            document.getElementById('resendToken').style.display = 'none'
            this.$refs.accountActivated.showSuccess()
          } else {
            this.$store.state.error = error
            this.$store.state.error_response = error.response
            this.$store.state.proces_Name = 'Resend token'
            this.$store.state.error_message = error.response.data
            this.$router.push({ name: 'ErrorPage' })
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";
#resendToken {
  font-family: $text-family;
  // display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  display: flex;
}
.smallText {
  font-size: 12px;
}
.orange_button{
  width: max-content;
}
.activateBTN{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
</style>
