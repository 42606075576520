<template>
  <div class="small_main_container2">
    <div class="mytabs2">
      <input type="radio" id="tab_list_notification" name="mytabs2" checked="checked" >
      <label  for="tab_list_notification">Lista powiadomień  <i class="fa-solid fa-users"></i></label>
      <div class="tab2">
        <b-overlay :show=loadingList rounded="sm"
                   variant="dark"
                   opacity="0.5">
        <div class="notifyTable">
          <b-table striped hover :items="notificationAdminData"
                   class="b-table"
                   variant="primary"
                   responsive
                   sticky-header
                   :fields="fields"
                   @row-clicked="rowClick"
                   :tbody-tr-class="rowClass"
          >
            <template #cell(active)="data">
              {{ data.item.active | active}}
            </template>
            <template #cell(createdList)="data">
              {{ data.item.createdList | list}}
            </template>
            <template #cell(timeFrom)="data">
              {{ data.item.timeFrom | date}}
            </template>
            <template #cell(timeTo)="data">
              {{ data.item.timeTo | date}}
            </template>
            <template #cell(editTime)="data">
              {{ data.item.editTime | date}}
            </template>
            <template #cell(creationTime)="data">
              {{ data.item.creationTime | date}}
            </template>
            <template #cell(type)="data">
              {{ data.item.type | type}}
            </template>
          </b-table>
        </div>
          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label">Proszę czekać .... ładowanie listy</p>
            </div>
          </template>
        </b-overlay>
        <b-button variant="primary" @click="fetchData">Odświerz <i class="fa-solid fa-arrows-rotate"></i></b-button>
      </div>
      <input type="radio" id="tab_add_notification" name="mytabs2" >
      <label  for="tab_add_notification">Dodaj powiadomienie  <i class="fa-solid fa-bell"></i></label>
      <div class="tab2">
        <b-overlay :show="sendFormOverlay" rounded="sm"
        variant="dark"
        opacity="0.5">
        <div class="formMessage">
        <b-form @submit="sendForm">
          <b-form-group
            id="input-group-1"
            label="Temat wiadomości:"
            label-for="input-1"
            description="Wprowadź krótki tytuł wiadomości"
          >
            <b-form-input
              id="input-1"
              v-model="form.topic"
              placeholder="Wprowadź temat"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Treść wiadomości" label-for="input-2">
            <b-form-textarea
              id="input-2"
              v-model="form.content"
              placeholder="Wprowadź treść"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-3" label="Typ:" label-for="input-3" description="Standardowa - wiadomość wysyłana do wszystkich użytkowników w normalnej kolejce. Techniczna wiadomość o wysokim piorytecie. Reklamowa wiadomość dla osób które zaakceptowały reklamy.">
            <b-form-select
              id="input-3"
              v-model="form.type"
              :options="type"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-group-4" label="Data od:" label-for="timeFrom" description="Od kiedy ma być dostarczana">
            <b-form-datepicker
              id="timeFrom"
              v-model="form.timeFrom"
              required
              class="mb-2">
            </b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-group-5" label="Data do:" label-for="timeTo" description="Do kiedy ma być ważna wiadomość">
            <b-form-datepicker
              id="timeTo"
              v-model="form.timeTo"
              required
              class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-group-6" >
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.active"
              name="checkbox-1"
              :value=true
              :unchecked-value=false
            >
              Wiadomość aktywna
            </b-form-checkbox>
          </b-form-group>

          <b-button type="submit" variant="primary">Wyślij</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>
      </div>
          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label">Proszę czekać .... generowanie trasy patrolowej</p>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Notification.vue',
  computed: {
    ...mapState([
      'notificationAdminData'
    ])
  },
  data () {
    return {
      loadingList: true,
      sendFormOverlay: false,
      form: {
        topic: '',
        content: '',
        type: null,
        timeFrom: '',
        timeTo: '',
        active: true
      },
      type: [{ text: 'Wybierz opcję', value: null },
        { text: 'Wiadomość standardowa', value: 'Normal' },
        { text: 'Wiadomość reklamowa', value: 'Adv' },
        { text: 'Wiadomość techniczna', value: 'Tech' }],
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'topic', label: 'Temat' },
        { key: 'content', label: 'Treść' },
        { key: 'active', label: 'Status' },
        { key: 'createdList', label: 'Lista' },
        { key: 'timeFrom', label: 'Od' },
        { key: 'timeTo', label: 'Do' },
        { key: 'type', label: 'Typ' },
        { key: 'creationTime', label: 'Utworzono' },
        { key: 'creatorDbId', label: 'Autor' },
        { key: 'editTime', label: 'Edytowano' },
        { key: 'editorDbId', label: 'Edytor' }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loadingList = true
      this.$store.dispatch('get_admin_notification_list')
        .then(data => {
          this.loadingList = false
        })
    },
    rowClick (item, index, event) {
      this.$router.push({ name: 'EditNotification', params: { id: item.id } })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.createdList === false) return 'table-danger'
    },
    sendForm (event) {
      this.sendFormOverlay = true
      event.preventDefault()
      const dateTo = new Date(this.form.timeTo)
      const dateFrom = new Date(this.form.timeFrom)
      this.form.timeTo = dateTo.getTime() / 1000
      this.form.timeFrom = dateFrom / 1000
      this.$store.dispatch('post_addNotification', this.form)
        .then(data => {
          const tabs = document.getElementById('tab_list_notification')
          tabs.checked = true
          this.sendFormOverlay = false
          this.form = {
            topic: '',
            content: '',
            type: null,
            timeFrom: '',
            timeTo: '',
            active: true
          }
        }).catch(error => {
          this.catchErrorPage(error, 'Notification')
        })
    }
  },
  filters: {
    active: function (value) {
      if (!value) return 'Nieaktywna'
      if (value) return 'Aktywna'
      return ''
    },
    list: function (value) {
      if (!value) return 'Niewygenerowano!'
      if (value) return 'Wygenerowano'
      return ''
    },
    type: function (value) {
      if (!value) return 'Błąd!'
      if (value === 'Normal') return 'Standardowa'
      if (value === 'Adv') return 'Reklamowa'
      if (value === 'Tech') return 'Techniczna'
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/views/main_layout';
@import 'src/views/others';
.notifyTable {
  background: white !important;
}
.small_main_container {
  padding: 0 0 5px 0px;
  background: unset;
  margin-top: 10px;
}
.mytabs2 {
  display: flex;
  flex-wrap: wrap;
}
.mytabs2 input[type="radio"] {
  display: none;
}
.mytabs2 label {
  font-family: 'Itim', cursive;
  background: #ee6c4d;
  margin-right: 10px;
  border-radius: 5px 5px 0 0;
  padding: 15px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.mytabs2 .tab2 {
  background: #293241;
  width: 100%;
  padding: 20px;
  order: 1;
  display: none;
  color: #fff;
}
.mytabs2 input[type="radio"]:checked + label + .tab2 {
  display: block;
}
.mytabs2 input[type="radio"]:checked + label {
  background: #293241;
}
.mytabs2 input[type="radio"]:not(:checked):hover + label {
  background: #293241;
}
.mytabs2 label i {
  font-size: 20px;
}
.formMessage {
  display: unset !important;
}
.b-table{
  max-height: 500px;
}
</style>
