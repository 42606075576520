<template>
  <div class="main">
    <div>
      <div class="small_main_container">
        <div class="group_element">
          <div class="text">
            Przywracanie hasła
          </div>
          <div class="text">
            Nazwa użytkownika lub email
          </div>
          <div class="grid">
            <div :class="[!validator && login === '' ? 'icon' : 'hide']">
              <i class="fa-regular fa-circle-question"></i>
            </div>
            <div :class="[validator && login === '' ? 'icon' : 'hide']">
              <i class="fa-regular fa-circle-question"></i>
            </div>
            <div :class="[validator && login !== '' ? 'icon' : 'hide']">
              <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
            </div>
            <div :class="[!validator && login !== '' ? 'icon' : 'hide']">
              <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
            </div>
            <input placeholder="Wprowadź login lub email" @change="valid_login" id="login_input_forgot" v-model="login">
            <div class="hint_Text">{{ hint }}</div>
          </div>
        </div>
        <div class="orange_button" v-if="validator" @click="send_request">{{btnText}}<i class="fa-solid fa-right-to-bracket"></i></div>
        <div class="orange_button_disabled" v-if="!validator">Przejdź dalej  <i class="fa-solid fa-right-to-bracket"></i></div>
      </div>
      <div class="footer" @click="click_to_register">
        Nowy w TaPo24.pl Załóż konto <i class="fa-regular fa-hand-pointer"></i>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'PasswordRecovery.vue',
  methods: {
    click_to_register () {
      this.$router.push({ name: 'Register' })
    },
    valid_login () {
      if (this.login.length >= 3 || this.login.includes('@')) {
        document.getElementById('login_input_forgot').className = ''
        this.validator = true
        this.hint = ''
      } else {
        this.validator = false
        document.getElementById('login_input_forgot').className = ''
        document.getElementById('login_input_forgot').className = 'test'
        this.hint = 'Wprowadź login do długości conajmniej 5 znaków lub email'
      }
    },
    send_request () {
      if (!this.clicked) {
        this.clicked = true
        this.btnText = 'Zaczekaj '
        const headers = {
          'Content-Type': 'application/json',
          'INSTALLATION-UID': this.$store.state.UID
        }
        const payload = {
          login: this.login.trim(),
          password: ''
        }
        axios
          .post(this.$store.state.path_api + '/register/request_Forgot_password', payload, { headers })
          .then(response => {
            this.$router.push({ name: 'SuccessRequestForgotPassword' })
          })
          .catch(error => {
            this.$store.state.error = error
            this.$store.state.proces_Name = 'Password recovery'
            this.$store.state.error_response = error.response
            this.$store.state.error_message = error.response.data
            this.$router.push({ name: 'ErrorPage', params: { dbid: this.dbid } })
          })
      }
    }
  },
  watch: {
    'login' () {
      this.valid_login()
    }
  },
  data () {
    return {
      login: '',
      hint: '',
      validator: false,
      clicked: false,
      btnText: 'Przejdź dalej  '
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto;
  grid-gap: 6px;
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  align-items: center;
}
.password_and_forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.orange_button_disabled {
  margin-top: 10px;
  width: auto;
  justify-content: center;
}
.forgot:hover {
  cursor: pointer;
  background-color: #9cbad3;
  border-radius: 8px;
}
.forgot{
  background-color: rgba(41, 50, 65, 0.43);
  border-radius: 8px;
  transition: 0.5s;
}
.orange_button {
  width: auto;
  justify-content: center;
}
input{
  width: auto;
  border-width: 0;
  border-radius: 5px;
  padding: 4px 10px;
  margin-left: 10px;
}
.group_element{
  font-family: $text-family;
  font-weight: bold;
}
.test {
  background: #ff0000;
  border: 5px solid #bd0000;
  border-radius: 5px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.input_box{
  display: flex;
  margin-bottom: 20px;
}
.text {
  margin-bottom: 10px;
}
.footer{
  margin: 0 10px;
  width: auto;
  background-color: #3D5A80;
  border-radius: 8px;
  padding: 20px 10px;
  height: min-content;
  font-family: $text-family;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
}
.footer:hover {
  cursor: pointer;
  background-color: #9cbad3;
}
.small_main_container{
  margin: 20px 10px 10px 10px;
}
.hide {
  visibility: hidden;
  display: none;
}
.hint_Text {
  grid-row-start: 2;
  grid-column-start: 2;
  margin-left: 10px;
  font-weight: lighter;
  font-size: 12px;
}
@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
