<template>
  <div>
  <div class="small_main_container" id="errorAccountAlreadyUnsubscribeAdv">
    <div>
      <div>
        Błąd
      </div>
      <i class="fa-solid fa-circle-exclamation" style="font-size: 30px; color: #bd0000" ></i>
      <div class="smallText">
        <div>
          To konto jest ma już dezaktywowaną subskrypcję powiadomień marketingowych. Zaloguj się. Zachęcamy do polubienia nas na Facebooku oraz Instagramie.
        </div>
        <div class="social">
          <div class="social facebook" @click="globalGoTo('https://www.facebook.com/TaPo24/','Link do strony ', 'web', 'SOCIAL')">
            <div class="icon social facebook"><span><i class="fab fa-facebook-f"></i></span></div>
          </div>
          <div class="social instagram" @click="globalGoTo('https://www.instagram.com/tapo24.pl/','Link do strony IG', 'web', 'SOCIAL')">
            <div class="icon social instagram"><span><i class="fab fa-instagram"></i></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="footer" @click="click_to_login" id="footerActivate" v-if="uidExist">
      Zaloguj się <i class="fa-regular fa-hand-pointer"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountIsUnsubscribedAdv',
  data () {
    return {
      uidExist: false
    }
  },
  created () {
    const UID = localStorage.getItem('UID')
    if (UID) {
      this.uidExist = true
    }
  },
  methods: {
    showSuccess () {
      document.getElementById('errorAccountAlreadyUnsubscribeAdv').style.visibility = 'unset'
      document.getElementById('errorAccountAlreadyUnsubscribeAdv').style.display = 'flex'
      document.getElementById('footerActivate').style.visibility = 'unset'
      document.getElementById('footerActivate').style.display = 'flex'
    },
    click_to_login () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

#errorAccountAlreadyUnsubscribeAdv {
  font-family: $text-family;
  // display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  display: none;
  visibility: hidden;
}
.smallText {
  font-size: 12px;
  overflow-wrap: normal;
}
.social{
  display: inline-flex;
  list-style: none;
}
.social .icon{
  background: #293241;
  color: #ee6c4d;
  border-radius: 50%;
  padding: 5px;
  width: 15px;
  height: 15px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.footer{
  margin: 10px 10px;
  width: auto;
  background-color: #3D5A80;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  height: min-content;
  font-family: $text-family;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
  display: none;
  visibility: hidden;
}
.footer:hover {
  cursor: pointer;
  background-color: #9cbad3;
}

@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}

</style>
