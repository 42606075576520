<template>
  <div>
    <div id="search_top_bar2">
      <input type="text" style="border-radius: 10px; color: #6f768d; padding: 5px;" placeholder="Wprowadź nazwę aktu" v-model="serach_text">
      <div v-if="false" id="options_container">
        <div class="option">
          <input type="checkbox" class="checkBox" name="scales">
          <div>Wszystko</div>
        </div>
        <div class="option">
          <input type="checkbox" class="checkBox" name="scales">
          <div>Ustawy</div>
        </div>
        <div class="option">
          <input type="checkbox" class="checkBox" name="scales">
          <div>Rozporządzenia</div>
        </div>
        <div class="option">
          <input type="checkbox" class="checkBox" name="scales">
          <div>Akty policyjne</div>
        </div>
      </div>
    </div>
    <div id="main">
      <div id="pdf_container">
        <div class="top_bar">
          <div class="close" @click="close_pdf">Zamknij <i class="fas fa-times"></i></div>
        </div>
        <div id="adobe-dc-view"></div>
      </div>
      <div v-if="true" class="containerMy">
        <div v-for="(element, index) in filtered_law_list" v-bind:key="index">
          <div class="element" @click="gen_pdf(element.path, element.name)">
            <div class="icon"><i :class="[element.icon]"></i></div>
            <div class="label">{{ element.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PdfPattern.vue',
  created () {
    window.addEventListener('popstate', () => {
      if (this.$store.state.open_pdf) {
        this.$store.state.open_pdf = false
        if (this.$route.path.includes('law')) {
          this.$router.push({ path: '/law/' })
        }
        if (this.$route.path.includes('PdfPattern')) {
          this.$router.push({ path: '/PdfPattern/' })
        }
        if (this.$route.path.includes('About')) {
          this.$router.push({ path: '/About/' })
        }
        if (this.$route.path.includes('Register')) {
          this.$router.push({ path: '/Register/' })
        }
      }
    })
  },
  computed: {
    filtered_law_list () {
      return this.law_list.filter(element => element.name.toLowerCase().includes(this.serach_text.toLowerCase()) ||
        element.short.toLowerCase().includes(this.serach_text.toLowerCase()))
    }
  },
  data () {
    return {
      law_list: [
        { name: 'Pokwitowanie ZDR brak badań', path: 'WD_BBT.pdf', icon: 'fa-solid fa-calendar-days', short: 'Pokwitowanie zatrzymania dowodu rejestracyjnego za brak badań technicznych' },
        { name: 'Pokwitowanie ZPJ przekroczenie 24 pkt', path: 'WD_ZPJ24.pdf', icon: 'fa-solid fa-bicycle', short: 'Pokwitowanie zatrzymania prawa jazdy za  przekroczenie 24 punktów' },
        { name: 'Zezwolenie na odbiór pojazdu', path: 'WD_ZNO.pdf', icon: 'fa-solid fa-hand-point-up', short: 'Zezwolenie na odbiór pojazdu z parkingu strzeżonego' },
        { name: 'Dyspozycja holowania', path: 'WD_DYSPO.pdf', icon: 'fa-solid fa-plane-departure', short: 'Dyspozycja holowania' },
        { name: 'Powiadomienie usunięcia', path: 'WD_POW.pdf', icon: 'fa-solid fa-bell', short: 'Powiadomienie usunięcia' },
        { name: 'Karta MRD 5', path: 'WD_MRD5.pdf', icon: 'fa-solid fa-person-military-pointing', short: 'MRD5' }
      ],
      serach_text: ''
    }
  },
  methods: {
    close_pdf () {
      document.getElementById('pdf_container').style.visibility = 'hidden'
      this.$store.state.open_pdf = false
    },
    gen_pdf (path, fileName) {
      this.$router.push({ name: 'PdfViewer', params: { pdf_name: fileName, pdf_path: path, from: 'PdfPattern' } })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/grid";
@import "src/views/main_layout";
#pdf_container {
  overflow: hidden;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  background-color: #3D5A80;
}
.top_bar {
  background-color: #3D5A80;
  display: flex;
  justify-content: right;
}
.close {
  padding: 10px 10px;
  font-family: 'Readex Pro', sans-serif;
}
.close:hover {
  background-color: #98C1D9;
}
</style>
