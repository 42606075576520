<template>
  <div class="main">
    <div class="small_main_container" id="success">
      <div>
        <div>
          Poprawnie wygenerowano żądanie zmiany hasła
        </div>
        <i class="far fa-check-circle" style="font-size: 40px"></i>
        <div class="smallText">
          <div>
            Na podany adres e-mail został wysłany link z ponownym nadaniem hasła. Zachęcamy do polubienia nas na Facebooku oraz Instagramie.
          </div>
          <div class="social">
            <div class="social facebook" @click="globalGoTo('https://www.facebook.com/TaPo24/','Link do strony ', 'web', 'SOCIAL')">
              <div class="icon social facebook"><span><i class="fab fa-facebook-f"></i></span></div>
            </div>
            <div class="social instagram" @click="globalGoTo('https://www.instagram.com/tapo24.pl/','Link do strony IG', 'web', 'SOCIAL')">
              <div class="icon social instagram"><span><i class="fab fa-instagram"></i></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessRequestForgotPassword.vue'
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

#success {
  font-family: $text-family;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}
.smallText {
  font-size: 12px;
  overflow-wrap: normal;
}

@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}

</style>
