<template>
  <div class="main">
    <SetNewForgotPassword
    @on-send-clicked="sendRequestChangePassword($event)"/>
  </div>
</template>

<script>
import SetNewForgotPassword from '@/components/Register/SetNewForgotPasswordForm.vue'
import axios from 'axios'

export default {
  name: 'PasswordRecoveryExecuteChange.vue',
  components: { SetNewForgotPassword },
  methods: {
    sendRequestChangePassword (passowrd) {
      const headers = {
        'Content-Type': 'application/json',
        // 'INSTALLATION-UID': this.state.UID
        'INSTALLATION-UID': this.$route.params.uid
      }
      const payload = {
        token: this.$route.params.token,
        password: passowrd
      }
      axios.post(this.$store.state.path_api + '/register/execute_forgot_password', payload, { headers })
        .then(({ data }) => {
          this.$router.push({ name: 'SuccessChangePassword' })
        })
        .catch(error => {
          this.$store.state.error = error
          this.$store.state.error_response = error.response
          this.$store.state.proces_Name = 'Resend token'
          this.$store.state.error_message = error.response.data
          this.$router.push({ name: 'ErrorPage' })
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";

</style>
