<template>
  <div class="main_User">
    <div class="title">Informacje konta </div>
    <div class="container">
      <div class="element">
        <div class="title_Element">Login w serwisie:</div>
        <div class="parametr"><i class="fa-solid fa-user"></i> {{ userInfo.login }}</div>
        <div class="title_Element">Twój adres email:</div>
        <div class="parametr"><i class="fa-solid fa-envelope"></i> {{ userInfo.email}}</div>
        <div class="title_Element">Jeseś z nami od:</div>
        <div class="parametr"><i class="fa-solid fa-clock"></i> {{ userInfo.date }}</div>
        <div class="title_Element">Twój status w serwisie:</div>
        <div class="parametr"><i class="fa-solid fa-user-check"></i> {{ userStatusInfo }}</div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutUser.vue',
  data () {
    return {
      userInfo: {
        login: '',
        date: '',
        status: '',
        email: ''
      }
    }
  },
  methods: {
  },
  created () {
    this.$store.dispatch('get_user_info').then(data => {
      if (data) {
        this.userInfo = data
      }
    }
    )
  },
  computed: {
    userStatusInfo () {
      if (this.userInfo.status === 'User') {
        return 'Użytkownik'
      }
      if (this.userInfo.status === 'Admin') {
        return 'Administrator'
      }
      return '500 Error ;)'
    }
  }
}
</script>

<style scoped lang="scss">
.main_User {
}
@import "src/views/main_layout";
@import "src/views/others";
.element {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 6px;
}
</style>
